import { useEffect, useState, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { captureException, captureMessage } from '@sentry/nextjs';
import { apolloClient } from 'graphql/apollo-client';
import { CART } from 'graphql/queries';
import { CREATE_CUSTOMER_CART } from 'graphql/mutations/cart/createCustomerCart';
import { CREATE_NEW_CART } from 'graphql/mutations/cart/createCart';
import { CART_ID } from 'global/constants';
import { useAtom } from 'jotai'; // Assuming you use jotai for auth state
import { authAtom } from 'global/store'; // Update this path as needed

export default function useCreateCart() {
  const cookies = new Cookies();
  const [cartId, setCartId] = useState(cookies.get(CART_ID));
  const [isCreatingCart, setIsCreatingCart] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [{ token: isAuth }] = useAtom(authAtom); // Get authentication state
  const previousAuthState = useRef(isAuth);

  // Create empty cart mutation (for guest users)
  const [createCart, { loading: createLoading, error: createError }] = useMutation(CREATE_NEW_CART, {
    client: apolloClient,
    onCompleted: data => {
      try {
        const cart_id = data?.createEmptyCart;
        if (cart_id) {
          storeCartId(cart_id);
        } else {
          captureMessage('Create cart returned no ID', {
            level: 'warning'
          });
        }
      } catch (error) {
        captureException(error, {
          extra: { action: 'createCart onCompleted' }
        });
      }
      setIsCreatingCart(false);
    },
    onError: error => {
      captureException(error, {
        extra: { action: 'createCart', retryCount }
      });
      
      // Retry logic for network errors
      if (retryCount < 3) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          handleCreateCart();
        }, 1000 * (retryCount + 1)); // Exponential backoff
      }
      
      setIsCreatingCart(false);
    }
  });

  // Create customer cart mutation (for logged-in users)
  const [createCustomerCart, { loading: createCustomerLoading, error: createCustomerError }] = useMutation(CREATE_CUSTOMER_CART, {
    client: apolloClient,
    onCompleted: data => {
      try {
        // The field name might be different based on your GraphQL schema
        // Adjust this based on your actual mutation response
        const cart_id = data?.customerCart?.id || data?.createCustomerCart;
        
        if (cart_id) {
          storeCartId(cart_id);
          
          captureMessage('Customer cart created successfully', {
            level: 'info',
            extra: { cartId: cart_id }
          });
        } else {
          captureMessage('Create customer cart returned no ID', {
            level: 'warning'
          });
        }
      } catch (error) {
        captureException(error, {
          extra: { action: 'createCustomerCart onCompleted' }
        });
      }
      setIsCreatingCart(false);
    },
    onError: error => {
      captureException(error, {
        extra: { action: 'createCustomerCart' }
      });
      setIsCreatingCart(false);
    }
  });

  // Helper function to store cart ID in cookie and localStorage
  const storeCartId = useCallback((id) => {
    // Set to expire in 7 days
    const expires = dayjs().add(7, 'd').toDate();
    
    // Set as cookie
    cookies.set(CART_ID, id, { path: '/', expires });
    setCartId(id);
    
    // Also save to localStorage as backup
    if (typeof window !== 'undefined') {
      localStorage.setItem('baltbaby_cart_id', id);
    }
    
    captureMessage('Cart ID stored', {
      level: 'info',
      extra: { cartId: id, isAuth: Boolean(isAuth) }
    });
  }, [cookies, isAuth]);

  // Fetch existing cart to verify it's active
  const [getCart, { loading: cartLoading, error: cartError, data: cartData }] = useLazyQuery(CART, {
    variables: { id: cartId },
    client: apolloClient,
    fetchPolicy: 'network-only', // Force network request instead of using cache
    onCompleted: data => {
      if (data?.cart) {
        captureMessage('Cart fetch successful', {
          level: 'info',
          extra: {
            cartId: cartId,
            itemCount: data.cart?.items?.length || 0,
            isAuth: Boolean(isAuth)
          }
        });
      } else {
        captureMessage('Cart fetch returned null cart', {
          level: 'warning',
          extra: { cartId, isAuth: Boolean(isAuth) }
        });
        if (!isCreatingCart) {
          handleCreateCart();
        }
      }
    },
    onError: error => {
      captureException(error, {
        extra: { action: 'getCart', cartId, isAuth: Boolean(isAuth) }
      });
      
      // If cart isn't active, create a new one
      if (error?.message?.includes("isn't active")) {
        handleCreateCart();
      }
    }
  });

  // Initial cart verification
  const { error: initialCartError } = useQuery(CART, {
    variables: { id: cartId },
    client: apolloClient,
    skip: !cartId, // Skip if no cartId exists yet
    fetchPolicy: 'network-only'
  });

  // Handle cart creation based on auth state
  const handleCreateCart = useCallback(() => {
    if (!isCreatingCart) {
      setIsCreatingCart(true);
      
      if (isAuth) {
        // For logged-in users, create a customer cart
        createCustomerCart();
        captureMessage('Creating customer cart', { level: 'info' });
      } else {
        // For guests, create an empty cart
        createCart();
        captureMessage('Creating guest cart', { level: 'info' });
      }
    }
  }, [createCart, createCustomerCart, isCreatingCart, isAuth]);

  // Function to refresh cart
  const refreshCart = useCallback(() => {
    if (cartId) {
      getCart();
    } else {
      handleCreateCart();
    }
  }, [cartId, getCart, handleCreateCart]);

  // Check for auth state changes
  useEffect(() => {
    // If user just logged in, need to create a new customer cart
    if (isAuth && !previousAuthState.current) {
      captureMessage('User logged in, creating customer cart', { level: 'info' });
      handleCreateCart();
    }
    
    // If user just logged out, create a new guest cart
    if (!isAuth && previousAuthState.current) {
      captureMessage('User logged out, creating guest cart', { level: 'info' });
      handleCreateCart();
    }
    
    previousAuthState.current = isAuth;
  }, [isAuth, handleCreateCart]);

  // Check for and handle missing or invalid cart
  useEffect(() => {
    const newCartNeeded = !cartId || (initialCartError && initialCartError?.message?.includes("isn't active"));
    
    if (newCartNeeded && !isCreatingCart) {
      captureMessage('Creating new cart', {
        level: 'info',
        extra: { 
          reason: !cartId ? 'No cart ID' : 'Cart not active',
          currentId: cartId,
          isAuth: Boolean(isAuth)
        }
      });
      handleCreateCart();
    }
    
    // Also check localStorage as a backup source
    if (!cartId && typeof window !== 'undefined') {
      const localStorageCartId = localStorage.getItem('baltbaby_cart_id');
      if (localStorageCartId) {
        captureMessage('Recovering cart ID from localStorage', {
          level: 'info',
          extra: { localStorageCartId, isAuth: Boolean(isAuth) }
        });
        
        // Set the recovered ID in cookies
        storeCartId(localStorageCartId);
      }
    }
  }, [cartId, initialCartError, handleCreateCart, isCreatingCart, isAuth, storeCartId]);

  // Return useful values for other components
  return {
    cartId,
    isLoading: createLoading || createCustomerLoading || cartLoading || isCreatingCart,
    error: createError || createCustomerError || cartError || initialCartError,
    refreshCart,
    cartData: cartData?.cart,
    isAuth: Boolean(isAuth)
  };
}